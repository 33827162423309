<template>
  <div v-if="!isButton" class="menu-item" :class="{ disabled: isLoading }" @click="handleLogout()">
    <div class="icon-wrapper flex-center">
      <WfIcon icon="log-out" />
    </div>
    <div class="wrapper">
      <div v-text="isLoading ? 'Please wait...' : 'Log out'"></div>
    </div>
  </div>
  <button
    v-else
    class="button button--tertiary flex-center"
    :class="{ disabled: isLoading }"
    :disabled="isLoading"
    @click="handleLogout()"
  >
    <div class="icon-wrapper flex-center">
      <WfIcon icon="log-out" color="#111" />
    </div>
    <div class="wrapper">
      <div v-text="isLoading ? 'Please wait...' : 'Log out and authorize new sites'"></div>
    </div>
  </button>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { dispatch } from '../../figma/ui-message-handler';

import WfIcon from '@/components/WfIcon.vue';
import { trackEvent, trackUser, PLUGIN_ADDITIONAL_ACTION_TAKEN } from '@/helpers/analytics';

export default {
  components: {
    WfIcon,
  },
  props: ['isButton'],
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState(['user', 'token']),
  },
  methods: {
    ...mapActions(['revokeToken', 'logUserOut']),
    ...mapMutations([
      'setUser',
      'setToken',
      'setUserInfo',
      'setUserSites',
      'setIsLoggedIn',
      'setCurrentPage',
      'setAblyRealtime',
      'setAblyChannel',
    ]),

    async handleLogout() {
      this.isLoading = true;
      if (!this.isButton) this.$emit('updateIsLoading', true);

      const body = new FormData();
      body.append('writeKey', this.token);
      const response = await this.logUserOut(body);
      if (response) {
        dispatch('setClientStorage', { name: 'token', data: null });
        trackEvent(PLUGIN_ADDITIONAL_ACTION_TAKEN, { actionName: 'Logout' });
        trackUser();

        this.setUser(null);
        this.setToken(null);
        this.setUserInfo(null);
        this.setUserSites(null);
        this.setIsLoggedIn(false);
        this.setCurrentPage({ name: 'home', params: {} });
        this.setAblyRealtime(null);
        this.setAblyChannel(null);

        this.isLoading = false;
        if (!this.isButton) this.$emit('updateIsLoading', false);
      }
    },
  },
};
</script>

<template>
  <div class="wf-awaiting-connection">
    <div class="wf-call-to-action">
      <div class="instructions">
        <template v-if="selectedSiteName != null">
          <div class="wf-icon-wrapper ripple">
            <WfIcon icon="select-site" />
            <template v-if="selectedSiteName != null">
              <div class="ripple-ring"></div>
              <div class="ripple-ring"></div>
              <div class="ripple-ring"></div>
            </template>
          </div>
          <div style="margin-bottom: 8px"><strong>Waiting for a Webflow connection...</strong></div>
          To enable sync, open your "{{ selectedSiteName }}" site in Webflow and launch the Figma to Webflow App.
        </template>
        <template v-else>
          <div class="wf-icon-wrapper">
            <WfIcon icon="select-site" />
          </div>
          <div style="margin-bottom: 8px"><strong>Select your Webflow site...</strong></div>
          Choose your Webflow site from the dropdown. Then, click "Open project in Webflow" to launch the Figma to
          Webflow App and start syncing.
        </template>
      </div>
    </div>
    <div class="wf-cta-section">
      <a
        :href="`https://${selectedSiteShortName != null ? `${selectedSiteShortName}.design.` : ''}webflow.com`"
        target="_blank"
        class="button button--primary"
      >
        <div>Open project in Webflow</div>
        <div class="icon-wrapper flex-center">
          <WfIcon icon="external-link" />
        </div>
      </a>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

import WfIcon from '@/components/WfIcon.vue';

export default {
  components: {
    WfIcon,
  },
  computed: {
    ...mapState(['userSites', 'selectedSiteId']),
    selectedSiteShortName() {
      return this.userSites.find((site) => site.id === this.selectedSiteId)?.shortName;
    },
    selectedSiteName() {
      return this.userSites.find((site) => site.id === this.selectedSiteId)?.displayName;
    },
  },
};
</script>

<template>
  <div v-if="!syncedSuccessfully" class="wf-cta-section button-wrapper" :class="{ 'has-warning': $parent.isBlocking }">
    <button
      v-if="$parent.hasInfo && !$parent.isBlocking"
      class="button button--primary flex-center"
      @click="handleSyncComponents()"
      :disabled="isLoading || $parent.isBlocking"
    >
      <template v-if="!isLoading">Sync to Webflow</template>
      <template v-else>
        <span class="small-loader"></span>
        <span>Preparing to sync...</span>
      </template>
    </button>
    <button
      v-else
      class="button button--primary flex-center"
      @click="handleGetComponentsInfo()"
      :disabled="isLoading || !$parent.hasOneOrMoreFrameSelected"
    >
      <template v-if="!isLoading">Continue</template>
      <template v-else>
        <span class="small-loader"></span>
        <span>Getting details...</span>
      </template>
    </button>

    <WfWarning
      v-if="$parent.isBlocking && $parent.hasOneOrMoreFrameSelected && $parent.hasInfo"
      :is-blocking="true"
      :is-components-sync="true"
      :message="`No components are present in your selection. <br/>Select at least one component to continue.`"
    />
  </div>
  <div v-else class="wf-cta-section button-wrapper buttons-group" :class="{ 'has-warning': $parent.isBlocking }">
    <button class="button button--primary flex-center" @click="setSyncedSuccessfully(false)">Continue</button>
  </div>
</template>
<script>
import { logTime, getSyncDuration } from '@/helpers/helper';
import { log } from '@/helpers/logger';
import { mapState, mapMutations, mapActions } from 'vuex';
import { dispatch, handleEvent } from '@/figma/ui-message-handler';
import { Buffer } from 'buffer';

import WfWarning from '../ui/WfWarning.vue';
import { trackEvent, COMPONENTS_SYNC_INITIATED, COMPONENTS_SYNC_COMPLETED } from '@/helpers/analytics';

export default {
  components: {
    WfWarning,
  },
  props: {
    syncWithAbly: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      // syncedSuccessfully: false,
    };
  },
  mounted() {
    handleEvent('setSelectedComponents', (value) => this.setComponentsInfo(value));
    handleEvent('componentsSyncedSuccessfully', (value) => this.setComponentsSyncedSuccessfully(value));
  },
  computed: {
    ...mapState(['componentsToSync', 'variablesToSync', 'selectedSiteId', 'userInfo', 'syncedSuccessfully']),
  },
  methods: {
    ...mapActions(['createSyncData']),
    ...mapMutations(['setComponentsToSync', 'setVariablesToSync', 'setSyncedSuccessfully']),
    handleGetComponentsInfo() {
      this.isLoading = true;
      dispatch('getSelectedComponents');
    },
    setComponentsInfo(value) {
      this.$parent.hasInfo = true;
      this.$parent.isBlocking = value.components.length === 0 ? true : false;
      this.isLoading = false;
    },
    async handleSyncComponents() {
      const startTime = performance.now();
      trackEvent(COMPONENTS_SYNC_INITIATED, {
        numberOfComponents: this.componentsToSync.length,
        numberOfVariables: this.variablesToSync.length,
      });
      this.isLoading = true;
      await this.getComponents();
      // Send to new API
      try {
        const site_id = this.selectedSiteId;
        const workspace_id = this.userInfo.authorizedTo.workspaceIds[0];
        const payload = { components: this.componentsToSync, variables: this.variablesToSync };
        this.deleteImagePropertyRecursively(payload.components);
        // CHECK PAYLOAD SIZE
        let sizeInBytes = Buffer.byteLength(JSON.stringify(payload), 'utf8');
        let sizeInMegabytes = sizeInBytes / (1024 * 1024);
        const MAX_SIZE = 4.5;
        if (sizeInMegabytes > MAX_SIZE) {
          this.cancelUploadProcess(sizeInMegabytes);
          return;
        }

        const { data } = await this.createSyncData({ workspace_id, site_id, payload }); // Pushing ot S3
        try {
          // Calling a method from parent: https://stackoverflow.com/questions/46208610/call-parent-method-with-component
          await this.syncWithAbly(data.snapshotUrl);
        } catch (error) {
          log(error);
          dispatch('notify', '❌ Something went wrong while communicating with Webflow App. Please try again.');
          this.isLoading = false;
        }
      } catch (error) {
        log(error);
      }

      this.isLoading = false;

      trackEvent(COMPONENTS_SYNC_COMPLETED, {
        numberOfComponents: this.componentsToSync.length,
        numberOfVariables: this.variablesToSync.length,
        syncDuration: getSyncDuration(startTime),
      });
      this.setSyncedSuccessfully(true);
      logTime(startTime, [...this.componentsToSync, ...this.variablesToSync].length, 'Plugin to S3');
    },
    async getComponents() {
      dispatch('getComponents');
      const components = await new Promise((resolve) => handleEvent('setComponents', (value) => resolve(value)));
      this.setComponents(components);
    },
    setComponents(components) {
      const componentsVariables = components.filter((c) => c.variables.length > 0);
      const variables = componentsVariables.map((c) => c.variables);
      this.setComponentsToSync(components);
      this.setVariablesToSync(variables.flat(1).filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)); // Flats to unique array & Remove duplicates
    },
    setComponentsSyncedSuccessfully(value) {
      if (!value) return;

      this.isLoading = false;
      this.syncedSuccessfully = true;

      setTimeout(() => (this.syncedSuccessfully = false), 4000);
    },
    cancelUploadProcess(size) {
      log(`Components sync payload too large: ${size}`);
      dispatch(
        'notify',
        `❌ Component${this.componentsToSync.length === 1 ? '' : 's'} size is too big. Try divide into smaller parts or having smaller images.`
      );
      this.isLoading = false;
      this.setSyncedSuccessfully(false);
    },
    deleteImagePropertyRecursively(array) {
      array.forEach((obj) => {
        // Always delete the 'image' property from the current object
        delete obj.image;

        // If the object has children, call this function recursively on the children
        if (Array.isArray(obj.children) && obj.children.length > 0) {
          this.deleteImagePropertyRecursively(obj.children);
        }
      });
    },
  },
};
</script>

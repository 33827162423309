<template>
  <section class="wf-variables-sync wf-current-selection">
    <div v-if="!hasReadDesignSystemNotification" class="wf-ds-notification">
      <div class="icon-wrapper">
        <WfIcon icon="warning-icon" color="#D7A220" />
      </div>
      <div class="message">Webflow currently doesn't support Collections and Modes. You can only sync one of each.</div>
      <div class="icon-wrapper" @click="closeNotification()">
        <WfIcon icon="times" />
      </div>
    </div>
    <div v-if="syncedSuccessfully" class="wf-no-variables">
      <div class="wf-select-frame">
        <WfIcon icon="successfull-sync" />
        <div class="type type--small">
          <strong>Sync successful!</strong><br /><br />
          Go to your Webflow project to finish the sync or continue using the plugin.
        </div>
      </div>
      <div class="wf-cta-section no-padding-top buttons-group">
        <button class="button button--primary flex-center" @click="setSyncedSuccessfully(false)">Continue</button>
      </div>
    </div>
    <template v-else>
      <template v-if="collections.length > 0">
        <WfVariablesTable @setSelectedVariables="getSelectedVariables" ref="variablesTable" />
        <div class="wf-cta-section">
          <button
            class="button button--primary flex-center"
            @click="handleSyncVariables()"
            :disabled="isLoading || noVariablesSelected"
          >
            <template v-if="!isLoading">
              {{
                noVariablesSelected
                  ? 'No variables selected'
                  : `Sync ${selectedVariables.length} variable${selectedVariables.length === 1 ? '' : 's'} to Webflow`
              }}
            </template>
            <template v-else>
              <span class="small-loader"></span>
              <span>Syncing variables...</span>
            </template>
          </button>
        </div>
      </template>
      <div v-else class="wf-no-variables">
        <div class="wf-select-frame">
          <WfIcon icon="no-variables-selected" />
          <div class="type type--small">
            <strong>No variables</strong><br /><br />
            You currently don't have variables in this project. Go ahead and create some to sync them to Webflow.
          </div>
        </div>
        <div class="wf-cta-section">
          <button class="button button--primary" disabled>No variables to sync to Webflow</button>
        </div>
      </div>
    </template>
  </section>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { dispatch, handleEvent } from '@/figma/ui-message-handler';

import WfIcon from '@/components/WfIcon.vue';
import {
  trackEvent,
  PLUGIN_PAGE_VIEWED,
  VARIABLES_SYNC_INITIATED,
  VARIABLES_SYNC_COMPLETED,
} from '@/helpers/analytics';
import { log } from '@/helpers/logger';
import { getSyncDuration } from '@/helpers/helper';
import WfVariablesTable from '@/components/new-layout/WfVariablesTable.vue';

export default {
  components: {
    WfIcon,
    WfVariablesTable,
  },
  props: {
    syncWithAbly: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      collections: [],
      variables: [],
      modes: [],

      isLoading: false,
      selectedVariables: [],
      // syncedSuccessfully: false,
    };
  },
  mounted() {
    dispatch('getAllLocalCollections');
    dispatch('timerToGetAllLocalCollectionsEveryFiveSeconds');
    handleEvent('setAllLocalCollections', (value) => this.setAllLocalCollections(value));

    trackEvent(PLUGIN_PAGE_VIEWED, { pageName: 'Design System Sync - Variables' });
  },
  computed: {
    ...mapState(['selectedSiteId', 'userInfo', 'hasReadDesignSystemNotification', 'syncedSuccessfully']),
    noVariablesSelected() {
      return this.selectedVariables.length === 0;
    },
  },
  methods: {
    ...mapActions(['createSyncData']),
    ...mapMutations(['setHasReadDesignSystemNotification', 'setSyncedSuccessfully']),
    getSelectedVariables(payload) {
      this.selectedVariables = payload;
    },
    async handleSyncVariables() {
      const startTime = performance.now();
      trackEvent(VARIABLES_SYNC_INITIATED, {
        numberOfVariables: this.selectedVariables.length,
        typeOfVariables: this.selectedVariables.map((v) => v.type),
      });
      this.isLoading = true;
      const payload = { variables: this.selectedVariables };

      try {
        const site_id = this.selectedSiteId;
        const workspace_id = this.userInfo.authorizedTo.workspaceIds[0];
        const { data } = await this.createSyncData({ workspace_id, site_id, payload });
        try {
          await this.syncWithAbly(data.snapshotUrl);
        } catch (error) {
          log(error);
          dispatch('notify', '❌ Something went wrong while communicating with Webflow App. Please try again.');
          this.isLoading = false;
        }
      } catch (error) {
        log(error);
      }

      this.isLoading = false;
      trackEvent(VARIABLES_SYNC_COMPLETED, {
        numberOfVariables: this.selectedVariables.length,
        typeOfVariables: this.selectedVariables.map((v) => v.type),
        syncDuration: getSyncDuration(startTime),
      });
      this.setSyncedSuccessfully(true);
      setTimeout(() => this.setSyncedSuccessfully(false), 4000);
    },
    setAllLocalCollections(value) {
      this.updateArrays(this.collections, value.collections);
      this.updateArrays(this.modes, value.modes);
      this.updateArrays(this.variables, value.variables, true);

      this.$refs.variablesTable?.emitSelectedVariables(); // we are using ? because the child component might not be mounted yet
    },
    updateArrays(currentArray, newArray, hasValue = false) {
      const updates = this.getUpdatedObjects(currentArray, newArray, hasValue);
      if (updates.length > 0) {
        updates.forEach((obj) => {
          const item = currentArray.find((c) => c.id === obj.id);
          item.name = obj.name;
          item.value = obj.value;
        });
      }

      const news = this.getNewObjects(currentArray, newArray);
      if (news.length > 0) {
        news.forEach((item, i) => {
          if (!hasValue) {
            // this means it's a collection or mode
            item.isSelected = i === 0;
          } else {
            item.isSelected = true;
          }
          currentArray.push(item);
        });
      }

      const deleted = this.getDeletedObjects(currentArray, newArray);
      if (deleted.length > 0) deleted.forEach((item) => currentArray.splice(currentArray.indexOf(item), 1));
    },
    getNewObjects(currentArray, newArray) {
      // get objects from newArray that are not in currentArray
      // return an array of objects
      return newArray.filter((obj) => !currentArray.some((o) => o.id === obj.id));
    },
    getUpdatedObjects(currentArray, newArray, hasValue) {
      const updatedItem = this.getCurrentObjects(currentArray, newArray);
      return updatedItem.filter((newItem) => {
        const currentItem = currentArray.find((c) => c.id === newItem.id);
        if (!hasValue) {
          return newItem.name !== currentItem.name;
        }

        // if item type is color and the name or value are different, return newItem
        if (currentItem.type === 'COLOR') {
          return newItem.name !== currentItem.name || newItem.value !== currentItem.value;
        } else if (currentItem.type === 'LENGTH') {
          return (
            newItem.name !== currentItem.name ||
            newItem.value.value !== currentItem.value.value ||
            newItem.value.unit !== currentItem.value.unit
          );
        } else {
          return newItem.name !== currentItem.name || newItem.value !== currentItem.value;
        }
      });
    },
    getCurrentObjects(currentArray, newArray) {
      // get objects from currentArray that are in newArray
      // return an array of objects
      return newArray.filter((obj) => currentArray.some((o) => o.id === obj.id));
    },
    getDeletedObjects(currentArray, newArray) {
      // get objects from currentArray that are not in newArray
      // return an array of objects
      return currentArray.filter((obj) => !newArray.some((o) => o.id === obj.id));
    },

    closeNotification() {
      if (!this.hasReadDesignSystemNotification) {
        this.setHasReadDesignSystemNotification(true);
        dispatch('setRootPropertyValue', { prop: 'hasReadDesignSystemNotification', value: 'true' });
      }
    },
  },
};
</script>

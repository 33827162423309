<template>
  <div class="wf-design-system-sync-tabs buttons-group">
    <button
      :key="tab.id"
      v-for="tab in tabs"
      class="wf-tooltip-wrapper"
      @click="setDesignSystemSyncSelectedTab(tab.id)"
      :class="{ 'is-selected': tab.id === designSystemSyncSelectedTab }"
      :id="tab.id === 'components' ? 'selectModeTooltip' : 'variablesModeTooltip'"
    >
      <WfIcon :icon="tab.icon" />
    </button>
  </div>
</template>

<script>
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import { mapState, mapGetters, mapMutations } from 'vuex';

import WfIcon from '@/components/WfIcon.vue';

export default {
  components: {
    WfIcon,
  },
  data() {
    return {
      tabs: [
        { id: 'components', name: 'Components', icon: 'canvas' },
        { id: 'variables', name: 'Variables', icon: 'variables-tab' },
      ],
    };
  },
  mounted() {
    this.setupTooltips();
  },
  computed: {
    ...mapGetters(['isWebflowUser']),
    ...mapState(['isConnectedToWebflow', 'designSystemSyncSelectedTab']),
  },
  methods: {
    ...mapMutations(['setDesignSystemSyncSelectedTab']),
    setupTooltips() {
      tippy('#selectModeTooltip', {
        maxWidth: 200,
        theme: 'custom',
        placement: 'bottom',
        content: 'Selection mode',
      });
      tippy('#variablesModeTooltip', {
        maxWidth: 200,
        theme: 'custom',
        placement: 'bottom',
        content: 'Variables mode',
      });
    },
  },
};
</script>

import * as Ably from 'ably';
import store from '@/store';
import { trackEvent, WEBFLOW_DESIGN_SYSTEM_CONNECTED } from '@/helpers/analytics';
import { getAblyTokenRequest } from '@/api/ably-api';

export const setupAblyConnection = async () => {
  if (store.state.isConnectedToWebflow) return;

  const cachedRealtime = store.state.ablyRealtime;
  if (cachedRealtime) return cachedRealtime;

  const clientId = 'figma-ably';
  const realtime = new Ably.Realtime({
    clientId,
    transportParams: { remainPresentFor: 1000 },
    authCallback: async (_, callback) => {
      try {
        const data = { clientId, writeKey: store.state.token };
        const { data: tokenRequest } = await getAblyTokenRequest(data);
        callback(null, tokenRequest);
      } catch (error) {
        callback(error, null);
      }
    },
  });

  await realtime.connection.once('connected');
  console.log('Connected to Ably! ✅');

  store.commit('setAblyRealtime', realtime);
  return realtime;
};

const subscribeToPresence = async (channel) => {
  channel.presence.subscribe(async () => {
    const members = await channel.presence.get();

    const isFigmaPresent = members.some((m) => m.clientId === 'figma-ably');
    if (!isFigmaPresent) {
      // if we are not in the channel, we don't care if webflow is
      return;
    }

    const isWebflowPresent = members.some((m) => m.clientId === 'webflow-ably');
    if (isWebflowPresent) {
      trackEvent(WEBFLOW_DESIGN_SYSTEM_CONNECTED);
    }
    store.commit('setIsConnectedToWebflow', isWebflowPresent);
  });
};

const subscribeToMessages = async (channel) => {
  channel.subscribe((msg) => {
    if (msg.clientId !== 'webflow-ably') return;

    if (msg.name === 'update-hash') {
      const hashMap = msg.data;
      if (hashMap.length < 1) return;

      // Send updates to Figma code.ts and let them update
      // to success to WfDesignSystem component
      store.dispatch('updateWfIds', hashMap);
      return;
    }

    if (msg.name === 'sync-successfull') {
      store.dispatch('setComponentSyncing', true);
      return;
    }
  });
};

export const joinChannel = async (siteId) => {
  const realtime = store.state.ablyRealtime;

  const userId = store.state.user.id;
  const channel = realtime.channels.get(`webflow:${siteId}:${userId}`);
  store.commit('setAblyChannel', channel);

  await channel.attach();

  await subscribeToPresence(channel);
  await subscribeToMessages(channel);
  await channel.presence.enter(store.state.figmaFileName);

  return channel;
};

export const leaveChannel = async () => {
  store.commit('setIsConnectedToWebflow', false);
  const channel = store.state.ablyChannel;

  if (!channel || !channel.presence) {
    return;
  }

  await channel.presence.unsubscribe();
  await channel.presence.leave('Figma says Bye!');
  await channel.detach();
};

export const publishToChannel = async (message, payload) => {
  const channel = store.state.ablyChannel;
  await channel.publish(message, payload);
};

import axios from 'axios';

export const getAblyTokenRequest = async (data) => {
  const { writeKey: token, clientId: client_id } = data;
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  };
  const URL = `realtime-auth`;
  const payload = { client_id };
  return axios.post(URL, payload, { headers });
};
